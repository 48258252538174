<template>
    <div class="detail">
        <heads />
        <div class="con-main">
            <div class="hd-navs">
                <a-breadcrumb>
                    <a-breadcrumb-item><a :href="whereGoUrl">{{whereGo}}</a></a-breadcrumb-item>
                    <a-breadcrumb-item><a @click="goback">文物列表</a></a-breadcrumb-item>
                    <a-breadcrumb-item>详情</a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <div class="main">
                <h2>{{opt.title}}</h2>
                <!-- <div class="img-box">
                    <img v-for="item in list" :key="item" :src="item" alt="" class="imglist-item">
                </div> -->
                <p v-html="opt.infoString"></p>
            </div>
        </div>
        <foots />
    </div>
</template>
<script>
import heads from '../component/heads.vue';
import foots from '../component/foot.vue';
export default {
    name: 'detail',
    components: {heads, foots},
    data() {return {
        opt: {},
        list: [],
        whereGo: '',
        whereGoUrl: './',
        thUrl: 'http://192.168.90.121:8081/tbboot/'
    }},
    mounted() {
        this.pageInit();
    },
    methods: {
        // 导航
        fnYear() {
            this.whereGo = '藏品鉴赏';
            this.whereGoUrl = './';
        },
        fnPerson() {
            this.whereGo = '名人遗物';
            this.whereGoUrl = './?point=2';
        },
        // 初始化
        pageInit() {    
            const req = this.$route.query;
            req.type == 'times'? this.fnYear() : this.fnPerson();
            const opt = JSON.parse(window.sessionStorage.getItem('dtlInfo'));
            this.getImg(opt);
        },
        // 图片判断
        getImg(opt) {
            if (opt.infoString.indexOf(this.thUrl) != -1) {
                const ar1 = opt.infoString.split(' ');
                const an = [];
                ar1.forEach(v => {
                    if (v.indexOf('src=') != -1) {
                        const ar2 = v.split(this.thUrl);
                        ar2[0] += this.thePageUrl;
                        an.push(ar2.join('') + ' style="max-width: 1160px"');
                    } else if (v.indexOf('width=') != -1) {
                        an.push('');
                    } else if (v.indexOf('height=') != -1) {
                        an.push('');
                    } else if (v.indexOf('style="display:none;"') != -1) {
                        an.push('');
                    } else {
                        console.log(v)
                        an.push(v);
                    }
                })
                opt.infoString = an.join(' ');
                console.log(opt.infoString);
            }
            this.opt = opt;
        },
        goback() {
            this.$router.go(-1);
        }
    }
}
</script>

<style scoped>
    .hd-navs {
        width: 1160px;
        margin: 30px auto 0;
    }
    .hd-navs /deep/ .ant-breadcrumb-link a, .hd-navs /deep/ .ant-breadcrumb-link {
        font-size: 20px;
        font-weight: 600;
        color: #000;
    }
    .con-main {
    margin: 0 auto 60px;
    width: 1200px;
    background: #fff;
    box-shadow: 1px 5px 40px rgba(9,2,4,0.1);
    -webkit-box-shadow: 1px 5px 40px rgba(9,2,4,0.1);
    padding: 60px 20px 20px 20px;
}
    .main {
        min-height: 300px;
        width: 1160px;
        margin: 0 auto;
        font-size: 16px;
        padding: 10px 0 80px;
    }
    h2 {
        text-align: center;
        margin: 50px 0;
        font-weight: 600;
    }
    .img-box {
        width: 80%;
        margin: 0 auto;
    }
    .imglist-item {
        width: 100%;
        margin-bottom: 20px;
    }
</style>